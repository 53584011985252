import axios from '@/libs/api.request'


/**
 * 获取用户信息
 */
export const getInfo = () => {
  return axios.request({
    url: 'v1/wx/ref-token',
    method: 'get',
  })
}


/**
 * 绑定用户关系
 */
export const bindUser = (data) => {
  return axios.request({
    url: 'v1/user/bind-user',
    method: 'post',
    data: data
  })
}


/**
 * 获取用户钱包信息
 */
export const wallet = (data) => {
  return axios.request({
    url: 'v1/user/wallet',
    method: 'get',
    params: data
  })
}


/**
 * 获取用户信息
 */
export const Income = (data) => {
  return axios.request({
    url: 'v1/user/income',
    method: 'get',
    params: data
  })
}


/**
 * 获取验证码
 */
export const getCode = (data) => {
  return axios.request({
    url: 'v1/site/sms-code',
    method: 'post',
    data: data
  })
}

/**
 * 绑定手机号
 */
export const bindMobile = (data) => {
  return axios.request({
    url: 'v1/user/bind-mobile',
    method: 'post',
    data: data
  })
}

/**
 * 绑定手机号
 */
export const subordinate = (data) => {
  return axios.request({
    url: 'v1/user/subordinate',
    method: 'get',
    params: data
  })
}

/**
 * 
 */
export const loginLog = (data) => {
  return axios.request({
    url: 'loginLog',
    method: 'post',
    data
  })
}

/**
 * 修改通知
 */
export const noticeSet = (data) => {
  return axios.request({
    url: 'v1/user/notice-set',
    method: 'post',
    data
  })
}

/**
 * 获取公众号配置
 */
export const wxInfo = (data) =>{
  return axios.request({
    url: 'v1/wx/wx-info',
    method: 'get',
    params: data
  })
}

/**
 * 功能模块
 */
export const kmModule = (data) =>{
  return axios.request({
    url: 'v1/wx/km-module',
    method: 'get',
    params: data
  })
}

/**
 * 营业执照编辑
 */
export const UpdateLicense = (data) =>{
  return axios.request({
    url: 'v1/member-license/update-license',
    method: 'post',
    data: data
  })
}

/**
 * 营业执照详情
 */
export const licenseInfo = (data) =>{
  return axios.request({
    url: 'v1/member-license/info',
    method: 'get',
    params: data
  })
}

/**
 * 用于隐藏教练的分享二维码等的个人信息
 */
export const handlePost = (data) =>{
  return axios.request({
    url: 'v1/user/handle-posters-setting',
    method: 'post',
    data: data
  })
}

/**
 * 打卡详情
 */
export const punchInfo = (data) =>{
  return axios.request({
    url: 'v1/coach/punch',
    method: 'get',
    params: data
  })
}

/**
 * 地推码列表
 */
export const spreadLst = (data) =>{
  return axios.request({
    url: 'v1/coach/spread-list',
    method: 'get',
    params: data
  })
}

/**
 * 绑定地推人员
 */
export const bindingSpread = (data) =>{
  return axios.request({
    url: 'v1/coach/binding-spread',
    method: 'post',
    data: data
  })
}

/**
 * 账单推送
 */
export const dstExt = (data) =>{
  return axios.request({
    url: 'v1/orders/dst-ext',
    method: 'get',
    params: data
  })
}

/**
 * 每天登录领积分
 */
export const loginIntegral = (data) =>{
  return axios.request({
    url: 'v1/integral/login-integral',
    method: 'get',
    params: data
  })
}

/**
 * 分享同步微信信息
 */
export const lineSyncName = (data) =>{
  return axios.request({
    url: 'v1/user/line-sync-name',
    method: 'get',
    params: data
  })
}

/**
 * 考场数据分析
 */
export const fieldIncome = (data) =>{
  return axios.request({
    url: 'v1/user/field-income',
    method: 'get',
    params: data
  })
}

/**
 * 获取教练设置的代理比例
 * @param {*} data 
 * @returns 
 */
export const rateInfo = (data) =>{
  return axios.request({
    url: 'v1/user/rate-info',
    method: 'get',
    params: data
  })
}

/**
 * 设置分佣比例
 * @param {*} data 
 * @returns 
 */
export const updateRate = (data) =>{
  return axios.request({
    url: 'v1/user/update-rate',
    method: 'post',
    data: data
  })
}

/**
 * 申请换绑教练 查找教练
 * @param {*} data 
 * @returns 
 */
export const changeDstInfo = (data) =>{
  return axios.request({
    url: 'v1/user/dst-info',
    method: 'get',
    params: data
  })
}



/**
 * 申请换绑教练 提交资料审核
 * @param {*} data 
 * @returns 
 */
export const agentApply = (data) =>{
  return axios.request({
    url: 'v1/user/agent-apply',
    method: 'post',
    data: data
  })
}


/**
 * 申请换绑教练 查看详情
 * @param {*} data 
 * @returns 
 */
export const agentApplyInfo = (data) =>{
  return axios.request({
    url: 'v1/user/agent-apply-info',
    method: 'get',
    params: data
  })
}


/**
 * 申请换绑教练 申请列表
 * @param {*} data 
 * @returns 
 */
export const agentApplyList = (data) =>{
  return axios.request({
    url: 'v1/user/agent-apply-list',
    method: 'get',
    params: data
  })
}

